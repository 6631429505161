import React from "react";
import R14, {
  View,
  Redirect,
  //Image,
  Colors,
  TextInputField,
  SubmitButton,
  StyleSheet,
  IconButton,
  Touchable,
  PopUpMenu,
  PopUpMenuItem,
  HiddenInputField,
  Button,
  Chip,
  Text,
  Surface,
  CenteredForm,
} from "../core";

import FieldImage from "../components/FieldImage";
import CharacterEntryField from "../components/CharacterEntryField";
import FieldEntryField from "../components/FieldEntryField";
import CharactersEntryField from "../components/CharactersEntryField";
import CountdownTimer from "../components/CountdownTimer";
import ManualEntrySideSheet from "../components/ManualEntrySideSheet";
import FloatingLabel from "../components/FloatingLabel";

export default R14.connect(
  class ManualEntryScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRejectPress = this.handleRejectPress.bind(this);
      this.handlePreviousPress = this.handlePreviousPress.bind(this);
      this.handleNextPress = this.handleNextPress.bind(this);
      this.handlePageIndicatorPress = this.handlePageIndicatorPress.bind(this);
      this.handleKeyMapperAction = this.handleKeyMapperAction.bind(this);
      this.handleTimeout = this.handleTimeout.bind(this);
      this.handleSelectNextCharacter =
        this.handleSelectNextCharacter.bind(this);
      this.forwardSubmit = "next";
      this.manualEntryDomain = this.props.app.dm.manualEntry;
      this.keyMapper = this.props.app.ui.keyMapper.create("manualEntry", {
        repeat: false,
      });
      this.startTime = Date.now();
      this.state = {
        showKeymapOverlay: true,
      };
      this.init();
    }
    componentWillUnmount() {
      this.keyMapper && this.keyMapper.disconnect();
    }
    init() {
      this.initKeyMapper();
    }
    initKeyMapper() {
      this.keyMapper
        .onKeyDown(async ({ key }) =>
          this.manualEntryDomain.registerKeystroke(key)
        )
        .addAction("accept", ["Enter"], this.handleKeyMapperAction, {
          label: "Accept",
        })
        .addAction("reject", ["CtrlEnter"], this.handleKeyMapperAction, {
          label: "Reject",
        })
        .addAction(
          "previous",
          ["Ctrl,", "PageUp"],
          this.handleKeyMapperAction,
          { label: "Previous" }
        )
        .addAction("next", ["Ctrl.", "PageDown"], this.handleKeyMapperAction, {
          label: "Next",
        });
    }
    async handleKeyMapperAction({ e, actionName }) {
      e.preventDefault();
      switch (actionName) {
        case "next":
          await this.handleNextPress();
          break;
        case "previous":
          this.manualEntryDomain.hasPreviousQueueItem &&
            (await this.handlePreviousPress());
          break;
        case "reject":
          await this.handleRejectPress();
          break;
        case "accept":
          this.props.app.ui.form("manualEntryForm").submit();
          break;
      }
    }

    get manualEntryForm() {
      return this.props.app.ui.form("manualEntryForm");
    }
    get formData() {
      let ret = {
        values: {
          // updatedValue:
          //   this.props.item.updatedValue !== null &&
          //   this.props.item.updatedValue !== undefined
          //     ? this.props.item.updatedValue
          //     : this.props.item.value,
          forward: "load",
          action: this.manualEntryDomain.ACTION_ACCEPT,
        },
      };
      switch (this.props.item.mode) {
        case this.manualEntryDomain.MODE_FIELD:
          for (let annotation of this.props.item.annotations) {
            if (
              annotation.type === this.manualEntryDomain.ANNOTATION_TYPE_FIELD
            ) {
              ret.values.updatedValue =
                annotation.updatedValue !== null &&
                annotation.updatedValue !== undefined
                  ? annotation.updatedValue
                  : annotation.value;
              ret.values.annotationUuid = annotation.uuid;
              break;
            }
          }
          break;
      }

      return ret;
    }
    get mode() {
      return this.props.item.mode;
    }
    get autoForwardLastCharacter() {
      return true;
    }
    hasValueChanged() {
      let manualEntryForm = this.props.app.ui.form("manualEntryForm");
      if (!this.props.item || !manualEntryForm) return false;
      let ret = false;
      let value = null;
      let prevValue = null;
      switch (this.mode) {
        case this.manualEntryDomain.MODE_CHARACTERS:
        case this.manualEntryDomain.MODE_FIELD_CHARACTERS:
          break;
        case this.manualEntryDomain.MODE_FIELD:
          value = manualEntryForm.elmts.updatedValue.value;
          prevValue = this.props.item.updatedValue || this.props.item.value;
          ret = value !== prevValue;
          break;
        default:
          throw new Error("Unknown edit mode.");
      }
      return ret;
    }
    confirmNavigation() {
      if (!this.hasValueChanged()) return true;
      else
        return window.confirm(
          "Are you sure? Changes you have made have not been accepted."
        );
    }
    async handlePreviousPress() {
      this.confirmNavigation() &&
        this.manualEntryDomain.hasPreviousQueueItem &&
        (await this.props.app.nav.to("manualEntry", {
          queueItemNum: this.manualEntryDomain.previousQueueItemIdx + 1,
        }));
    }
    async handleNextPress() {
      this.confirmNavigation() &&
        this.manualEntryDomain.hasNextQueueItem &&
        (await this.props.app.nav.to("manualEntry", {
          queueItemNum: this.manualEntryDomain.nextQueueItemIdx + 1,
        }));
    }
    async handlePageIndicatorPress(idx) {
      this.confirmNavigation() &&
        (await this.props.app.nav.to("manualEntry", { queueItemNum: idx + 1 }));
    }
    async updateQueueItem(values) {
      this.props.app.ui.progressIndicator.show();
      await this.manualEntryDomain.updateQueueItem(values);
      this.props.app.ui.progressIndicator.hide({ timeout: 500 });
    }
    async handleRejectPress(options = {}) {
      //if (!window.confirm("Are you sure you want to reject this item?")) return;
      let manualEntryForm = this.manualEntryForm;
      manualEntryForm.elmts.forward.value = options.forward || "load";
      manualEntryForm.elmts.action.value = this.manualEntryDomain.ACTION_REJECT;
      manualEntryForm.submit();
    }
    async handleAcceptPress(options = {}) {
      let manualEntryForm = this.manualEntryForm;
      manualEntryForm.elmts.forward.value = options.forward || "load";
      manualEntryForm.elmts.action.value = this.manualEntryDomain.ACTION_ACCEPT;
      manualEntryForm.submit();
    }
    async handleSubmit(manualEntryForm) {
      let formVals = manualEntryForm.values;
      let submitVals = {};
      let reject = formVals.action === this.manualEntryDomain.ACTION_REJECT;
      switch (this.mode) {
        case this.manualEntryDomain.MODE_FIELD_CHARACTERS:
        case this.manualEntryDomain.MODE_CHARACTERS:
          let charactersEntryField =
            this.props.app.ui.charactersEntryField.getInstance(
              this.props.item.uid
            );

          submitVals.annotations =
            charactersEntryField.characters.getUpdateValues();
          // console.log(updateValues);
          // let rejectedAllChars = reject || updateValues.length ? true : false;
          // console.log('TEST REJECT ALL', rejectedAllChars);
          // // let rejectedAllChars = true;
          // submitVals.characters = updateValues.map((char) => {
          //   if (!reject && !char.reject) rejectedAllChars = false;
          //   return {
          //     uid: char.uid,
          //     updatedValue: "updatedValue" in char ? char.updatedValue : null,
          //     reject: char.reject || reject ? true : false,
          //   };
          // });
          break;
        // case this.manualEntryDomain.MODE_CHARACTERS:
        //   let rejectedAllChars = true;
        //   submitVals.characters = formVals.characters.map((char) => {
        //     if (!reject && !char.reject) rejectedAllChars = false;
        //     return {
        //       uid: char.uid,
        //       updatedValue: "updatedValue" in char ? char.updatedValue : null,
        //       reject: char.reject || reject ? true : false,
        //     };
        //   });
        //   // Automatically reject if everything rejected.
        //   if (rejectedAllChars) reject = true;
        //   break;
        case this.manualEntryDomain.MODE_FIELD:
          submitVals.annotations = this.manualEntryDomain
            .filterAnnotations(this.props.item.annotations, {
              type: this.manualEntryDomain.ANNOTATION_TYPE_FIELD,
              uuid: formVals.uuid,
            })
            .map((annotation) => {
              annotation.reject = reject;
              annotation.valid = !reject;
              annotation.updatedValue =
                formVals.updatedValue === annotation.value
                  ? null
                  : formVals.updatedValue;
              return annotation;
            });
          break;
        default:
          throw new Error("Unknown edit mode.");
      }

      if (reject) submitVals.reject = true;
      submitVals.startTime = this.startTime;

      try {
        await this.updateQueueItem(submitVals);
        if (formVals.forward === "review")
          return <Redirect to='manualEntryReview' />;
        else return <Redirect to='manualEntryLoad' />;
      } catch (err) {
        throw err;
        this.props.app.ui.form("manualEntryForm").addError(err.message);
      }
    }
    async handleTimeout() {
      if (this.props.app.dm.manualEntry.disableTimeouts) return;
      this.props.app.dm.manualEntry.timeout();
      this.props.app.nav.to("manualEntryTimeout");
    }
    async handleSelectNextCharacter(char) {
      if (this.autoForwardLastCharacter && !char) this.handleAcceptPress();
    }
    renderLabel(value) {
      if (!value) return null;
      let lArr = value.split("_");
      return lArr
        .map((val) => this.props.app.utils.str.capitalize(val.toLowerCase()))
        .join(" ");
    }
    renderControlsBottomRight() {
      let controls = [];
      controls.push(
        <View key='prevNext' style={styles.buttonRowButtonsLeft}>
          <FloatingLabel
            label={this.keyMapper.getActionKeyLabels("previous")[0]}
            visible={this.props.app.dm.manualEntry.keyOverlayMode}
            key='previous'
            position='bottom'
            offset={-16}
          >
            <IconButton
              // title="Previous"
              tooltip={`Previous [${
                this.keyMapper.getActionKeyLabels("previous")[0]
              }]`}
              icon='keyboardArrowLeft'
              size='large'
              onPress={this.handlePreviousPress}
              disabled={!this.manualEntryDomain.hasPreviousQueueItem}
            />
          </FloatingLabel>
          <FloatingLabel
            label={this.keyMapper.getActionKeyLabels("next")[0]}
            visible={this.props.app.dm.manualEntry.keyOverlayMode}
            key='next'
            position='bottom'
            offset={-16}
          >
            <IconButton
              tooltip={`Next [${this.keyMapper.getActionKeyLabels("next")[0]}]`}
              key='next'
              size='large'
              icon='keyboardArrowRight'
              onPress={this.handleNextPress}
              disabled={!this.manualEntryDomain.hasNextQueueItem}
            />
          </FloatingLabel>
        </View>
      );
      controls.push(
        <FloatingLabel
          label={this.keyMapper.getActionKeyLabels("reject")[0]}
          visible={this.props.app.dm.manualEntry.keyOverlayMode}
          position='bottom'
          key='reject'
          style={styles.buttonRowButton}
        >
          <Button
            title='Reject'
            key='reject'
            // name="action"
            // value={this.manualEntryDomain.ACTION_REJECT}
            onPress={this.handleRejectPress}
            tooltip={`Reject [${
              this.keyMapper.getActionKeyLabels("reject")[0]
            }]`}
            color={Colors.secondary}
            // style={styles.buttonRowButton}
          />
        </FloatingLabel>
      );
      controls.push(
        <FloatingLabel
          label={this.keyMapper.getActionKeyLabels("accept")[0]}
          visible={this.props.app.dm.manualEntry.keyOverlayMode}
          position='bottom'
          key='accept'
        >
          <SubmitButton
            title='Accept'
            key='accept'
            name='submit'
            value={this.manualEntryDomain.ACTION_ACCEPT}
            tooltip={`Accept [${
              this.keyMapper.getActionKeyLabels("accept")[0]
            }]`}
          />
        </FloatingLabel>
      );
      // }
      return controls;
    }
    renderPageIndicators() {
      // if (this.state.noResults) return null;
      let pageIndicators = [];
      for (let idx = 0; idx < this.manualEntryDomain.maxQueueLength; idx++) {
        let pageIndicatorCircleStyles = [styles.pageIndicatorCircle];
        if (
          idx !== this.manualEntryDomain.queueIdx &&
          idx < this.manualEntryDomain.queueLength
        ) {
          // Check to make sure the idx was processed
          let item = this.manualEntryDomain.queueItem(idx);
          console.log("item", item);
          switch (item.state) {
            // case this.manualEntryDomain.ACTION_ACCEPT:
            //   break;
            case this.manualEntryDomain.STATE_REVIEW:
              item.reject &&
                pageIndicatorCircleStyles.push(
                  styles.pageIndicatorCircleRejected
                );
              break;
            case this.manualEntryDomain.STATE_QUEUED:
              pageIndicatorCircleStyles.push(
                styles.pageIndicatorCircleUnprocessed
              );
              break;
          }

          // !this.manualEntryDomain.isQueueItemProcessed(idx) &&
          pageIndicators.push(
            <Touchable
              key={`pageIndicator${idx}`}
              onPress={() => this.handlePageIndicatorPress(idx)}
              style={styles.pageIndicator}
            >
              <View style={pageIndicatorCircleStyles} />
            </Touchable>
          );
        } else {
          if (idx === this.manualEntryDomain.queueIdx)
            pageIndicatorCircleStyles.push(styles.pageIndicatorCircleCurrent);
          else pageIndicatorCircleStyles.push(styles.pageIndicatorCircleNext);
          pageIndicators.push(
            <View key={`pageIndicator${idx}`} style={styles.pageIndicator}>
              <View style={pageIndicatorCircleStyles} />
            </View>
          );
        }
      }
      if (!pageIndicators.length) return null;
      return <View style={styles.pageIndicatorRow}>{pageIndicators}</View>;
    }

    renderTitleControlsRight() {
      let completeDate = new Date();
      completeDate.setSeconds(completeDate.getSeconds() + 30);
      let ret = [];
      this.props.app.dm.manualEntry.timeoutAt &&
        ret.push(
          <CountdownTimer
            key='timer'
            completeAt={this.props.app.dm.manualEntry.timeoutAt}
            onComplete={this.handleTimeout}
            style={styles.countdownTimer}
          />
        );
      if (this.props.item.reject)
        ret.push(
          <Chip
            key='rejected'
            label={"Rejected"}
            style={styles.rejectedIndicator}
          />
        );
      // ret.push(
      //   <PopUpMenu
      //     controlIcon='dotsVertical'
      //     key='menu'
      //     direction='downLeft'
      //     onSelect={(value, label) => {}}
      //   >
      //     <PopUpMenuItem
      //       icon='fileDocumentBoxCheck'
      //       iconSize='small'
      //       label='Accept &amp; Review'
      //       onPress={() => this.handleAcceptPress({ forward: "review" })}
      //       key='acceptReview'
      //     />
      //     <PopUpMenuItem
      //       icon='fileDocumentBoxRemove'
      //       iconSize='small'
      //       iconColor={Colors.secondary}
      //       labelTextStyle={styles.rejectReviewLabelText}
      //       label='Reject &amp; Review'
      //       onPress={() => this.handleRejectPress({ forward: "review" })}
      //       key='rejectReview'
      //     />
      //   </PopUpMenu>
      // );
      return ret;
    }
    render() {
      return (
        <CenteredForm
          // onKeyDown={this.handleKeyDown}
          name='manualEntryForm'
          title={this.props.app.dm.manualEntry.getLabel(
            this.props.item,
            this.props.app.dm.manualEntry.queueIdx
          )}
          initialValues={this.formData.values}
          // style={styles.manualEntry}
          titleControlsRight={this.renderTitleControlsRight()}
          validateBeforeSubmit
          style={styles.centeredForm}
          onSubmit={this.handleSubmit}
          controlsBottomRight={this.renderControlsBottomRight()}
          FooterComponent={this.renderPageIndicators()}
          SideSheetComponent={
            <ManualEntrySideSheet keyMapper={this.keyMapper} mode={this.mode} />
          }
        >
          <View>
            <HiddenInputField name='forward' />
            <HiddenInputField name='action' />
            {this.mode === this.manualEntryDomain.MODE_FIELD && (
              <FieldEntryField item={this.props.item} />
            )}
            {this.mode === this.manualEntryDomain.MODE_FIELD_CHARACTERS && (
              <CharactersEntryField
                name='fieldCharacters'
                mode={this.manualEntryDomain.MODE_FIELD_CHARACTERS}
                item={this.props.item}
                value={this.props.item.annotations}
                onSelectNext={this.handleSelectNextCharacter}
                keyMapper={this.keyMapper}
              />
            )}
            {this.mode === this.manualEntryDomain.MODE_CHARACTERS && (
              <CharactersEntryField
                name='fieldCharacters'
                mode={this.manualEntryDomain.MODE_CHARACTERS}
                item={this.props.item}
                value={this.props.item.annotations}
                onSelectNext={this.handleSelectNextCharacter}
                keyMapper={this.keyMapper}
                upperCase
              />
            )}
          </View>
        </CenteredForm>
      );
    }
  }
);
const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1,
  },
  buttonRowButton: {
    marginRight: 16,
  },
  buttonRowButtonsLeft: {
    marginRight: "auto",
    marginLeft: -16,
    flex: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  centeredForm: {
    width: 480,
    screen: ({ width }) => {
      if (width <= 480)
        return {
          width: "100%",
        };
    },
  },
  rejectedIndicator: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
    ...StyleSheet.margin(0),
  },
  noResults: {
    marginBottom: 24,
  },
  textInputField: {
    // minHeight: 22,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: 20,
  },
  countdownTimer: {
    paddingRight: 8,
  },
  pageIndicatorRow: {
    flex: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.4),
  },
  pageIndicator: {
    height: 24,
    width: 24,
    ...StyleSheet.padding(6),
  },
  pageIndicatorCircle: {
    height: 12,
    width: 12,
    borderRadius: 6,
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.8),
  },
  pageIndicatorCircleCurrent: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.8),
  },
  pageIndicatorCircleNext: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
  },
  pageIndicatorCircleRejected: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.8),
  },
  pageIndicatorCircleUnprocessed: {
    backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.1),
  },
  rejectReviewLabelText: {
    color: Colors.secondary,
  },
});
