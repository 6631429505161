import React from "react";
import R14, {
  Redirect,
  SelectMenuField,
  HiddenInputField,
  BackButton,
  Form,
  FieldGroup,
  FadeView,
  SubmitButton,
  TextInputField,
  View,
  Button,
  Colors,
  StyleSheet,
  Card,
  IconButton,
  RadioButtonGroupField,
} from "../core";

import Document from "./Document";
import DocumentAnnotation from "./DocumentAnnotation";
import AnnotationQuestionFieldGroup from "./AnnotationQuestionFieldGroup";
export default R14.connect(
  class DocumentAnnotationEditForm extends React.Component {
    constructor(props) {
      super(props);
      this.submitButtonRef = React.createRef();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleRejectPress = this.handleRejectPress.bind(this);
      this.handleEditPress = this.handleEditPress.bind(this);
      this.handleAnnotationPreviewLayout =
        this.handleAnnotationPreviewLayout.bind(this);
      this.handleValueSelectionValueChange =
        this.handleValueSelectionValueChange.bind(this);
      this.state = {
        annotationPreviewScale: null,
        annotationPreviewInitialScroll: null,
        valueVisible: this.initialValueVisible,
        editable: !this.props.shouldUseEditButton,
      };
      this.documentRef = this.props.documentRef || React.createRef();
    }

    get isEditMode() {
      return this.props.formData.values && this.props.formData.values.uid
        ? true
        : false;
    }
    get formData() {
      return this.props.formData;
    }
    get document() {
      return this.props.formData.document;
    }

    get annotation() {
      return this.props.formData.annotation;
    }
    get previewAnnotation() {
      return this.annotation.sizeExists
        ? this.annotation
        : this.annotation.parentAnnotation &&
          this.annotation.parentAnnotation.sizeExists
        ? this.annotation.parentAnnotation
        : null;
    }

    get initialValueVisible() {
      return (
        !this.formData.valueSelections ||
        (this.formData.valueSelectionEditable &&
          this.formData.values.valueSelection ===
            "__r14_valueSelectionEditable")
      );
    }
    get documentAnnotationForm() {
      return this.props.app.ui.form("documentAnnotationForm");
    }
    handleZoomInPress() {
      this.props.annotation.editForm.previewDocument.zoomIn();
    }
    handleZoomOutPress() {
      this.props.annotation.editForm.previewDocument.zoomOut();
    }
    handleZoomResetPress() {}
    async handleSubmit(form, options = {}) {
      if (!this.state.editable) return false;
      if (!this.props.onSubmit) throw new Error("onSubmit prop required.");
      return await this.props.onSubmit(form, options);
      // let editMethod = this.isEditMode ? "update" : "create";
      // let ret = null;
      // this.props.app.ui.progressIndicator.show();
      // try {
      //   let isSuccess = await this.props.app.dm.client[editMethod](form.values);
      //   this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      //   if (isSuccess) {
      //     let dataTable = this.props.refreshDataTable || "clients";
      //     this.props.app.ui.dataTable.exists(dataTable) &&
      //       (await this.props.app.ui.dataTable(dataTable).refresh());
      //     ret = <Redirect to={this.props.redirect || "clients"} />;
      //   }
      // } catch (err) {
      //   form.addError(err.message);
      // }
      // this.props.app.ui.progressIndicator.hide({ timeout: 750 });
      // return ret;
    }
    async handleRejectPress() {
      if (this.props.disableReject) return false;
      if (!this.state.editable) return false;
      if (!this.props.onReject) throw new Error("onReject prop required.");
      return await this.props.onReject(this.documentAnnotationForm);
      // this.documentAnnotationForm.submit({ reject: true });
    }
    async handleEditPress() {
      if (this.formData.questionFields) {
        this.documentAnnotationForm &&
          this.documentAnnotationForm.elmts.forEach(
            (elmt) => elmt.disabled && elmt.setDisabled(false)
          );
      } else {
        this.documentAnnotationForm.elements.value.setDisabled(false);
        this.documentAnnotationForm.elements.value.focus();
        this.documentAnnotationForm.elements.value.select();
      }

      this.setState({ editable: true });
    }
    handleValueSelectionValueChange(value, elmt) {
      // elmt.blurItems();
      // elmt.focus();
      let valueVisible = this.state.valueVisible;
      valueVisible = value === "__r14_valueSelectionEditable";
      this.state.valueVisible !== valueVisible &&
        this.setState({ valueVisible });
    }

    renderAnnotations() {
      let previewAnnotation = this.previewAnnotation;
      if (!previewAnnotation) return null;
      let annotations = [previewAnnotation];
      return annotations.map((annotation) => {
        let props = {
          key: annotation.uuid,
          width:
            annotation.width *
            this.document.image.width *
            this.state.annotationPreviewScale,
          height:
            annotation.height *
            this.document.image.height *
            this.state.annotationPreviewScale,
          top:
            annotation.top *
            this.document.image.height *
            this.state.annotationPreviewScale,
          left:
            annotation.left *
            this.document.image.width *
            this.state.annotationPreviewScale,
          onPress: this.handleAnnotationPress,
          active: true,
          activeStyle: this.getAnnotationStyle(annotation),
        };
        return <DocumentAnnotation {...props} />;
      });
    }
    getAnnotationStyle(annotation) {
      let annotationStyles = [styles.annotation];
      switch (annotation.type) {
        case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD:
          let annotationStyle =
            annotation.metadata.state ===
            this.props.app.dm.manualEntry.STATE_REVIEW
              ? annotation.metadata.reject
                ? styles.annotationReject
                : annotation.metadata.changed
                ? styles.annotationChanged
                : styles.annotationAccept
              : null;
          annotationStyle && annotationStyles.push(annotationStyle);
          break;
        case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD_GROUP:
          annotationStyles.push(styles.annotationFieldGroup);
          break;
      }
      return annotationStyles;
    }
    handleAnnotationPreviewLayout(event) {
      let annotation = this.previewAnnotation;
      if (!annotation) return false;
      let width =
        event.nativeEvent && event.nativeEvent.layout
          ? event.nativeEvent.layout.width
          : null;
      let imageWidth = this.document.image.width;
      let imageHeight = this.document.image.height;
      if (!width || !imageWidth || !imageHeight) return false;

      // Add 16 pixels padding
      let annotationWidth = annotation.width * imageWidth + 32;
      let annotationHeight = annotation.height * imageHeight + 32;
      let scrollTo = {};

      let annotationPreviewScale = width / annotationWidth;

      let annotationPreviewInitialScroll = {
        x: annotation.left * annotationPreviewScale * imageWidth + 16,
        y: annotation.top * annotationPreviewScale * imageHeight + 16,
        animated: false,
      };
      this.documentRef &&
        this.documentRef.current &&
        this.documentRef.current.scrollTo(annotationPreviewInitialScroll);
      this.setState({ annotationPreviewScale, annotationPreviewInitialScroll });
    }
    renderTitleControlsRight() {
      let titleControlsRight = this.props.headerControlsRight || [];
      return titleControlsRight;
      // return [
      //   <IconButton
      //     icon='magnifyPlus'
      //     key='zoomIn'
      //     size='small'
      //     style={[
      //       styles.zoomButton,
      //       this.props.zoomLevel > 1 && styles.zoomButtonActive,
      //     ]}
      //     onPress={this.handleZoomIn}
      //   />,
      //   <IconButton
      //     icon='magnifyMinus'
      //     key='zoomOut'
      //     size='small'
      //     style={[
      //       styles.zoomButton,
      //       this.props.zoomLevel < 1 && styles.zoomButtonActive,
      //     ]}
      //     onPress={this.handleZoomOut}
      //   />,
      // ];
    }
    renderButtons() {
      let ret = null;
      if (this.state.editable) {
        ret = [];
        if (!this.props.disableReject)
          ret.push(
            <Button
              title='Reject'
              key='reject'
              style={styles.rejectButton}
              name='action'
              value={this.props.app.dm.manualEntry.ACTION_REJECT}
              onPress={this.handleRejectPress}
              // tooltip={`Reject [${
              //   this.keyMapper.getActionKeyLabels("reject")[0]
              // }]`}
              color={Colors.secondary}
              // style={styles.buttonRowButton}
            />
          );
        ret.push(
          <SubmitButton
            title='Accept'
            key='submit'
            ref={this.submitButtonRef}
            disabled={this.props.disableSubmit}
          />
        );
      } else {
        ret = (
          <Button
            title='Edit'
            key='edit'
            style={styles.editButton}
            name='edit'
            color={Colors.secondary}
            onPress={this.handleEditPress}
          />
        );
      }
      return ret;
      // return this.state.editable ? (
      //   [
      //     <Button
      //       title='Reject'
      //       key='reject'
      //       style={styles.rejectButton}
      //       name='action'
      //       value={this.props.app.dm.manualEntry.ACTION_REJECT}
      //       onPress={this.handleRejectPress}
      //       // tooltip={`Reject [${
      //       //   this.keyMapper.getActionKeyLabels("reject")[0]
      //       // }]`}
      //       color={Colors.secondary}
      //       // style={styles.buttonRowButton}
      //     />,
      //     <SubmitButton title='Accept' key='submit' />,
      //   ]
      // ) : (
      //   <Button
      //     title='Edit'
      //     key='edit'
      //     style={styles.editButton}
      //     name='edit'
      //     onPress={this.handleEditPress}
      //   />
      // );
    }
    renderFormFields() {
      let ret = [];
      if (this.formData.questionFields) {
        ret.push(
          <AnnotationQuestionFieldGroup
            key='annotationQuestionFieldGroup'
            label={this.formData.label}
            questionFields={this.formData.questionFields}
            values={this.formData.values.values}
            required={this.formData.required}
            initialValueVisible={this.initialValueVisible}
            disabled={!this.state.editable}
            submitButtonRef={this.submitButtonRef}
            disableSubmit={this.props.disableSubmit}
          />
        );
      } else {
        if (this.formData.valueSelections)
          ret.push(
            <RadioButtonGroupField
              // value={this.state.radioButton ? true : false}
              key='radioButtonGroupField'
              onValueChange={this.handleValueSelectionValueChange}
              name='valueSelection'
              label={this.formData.label}
              items={this.formData.valueSelections}
              autoFocus={!this.initialValueVisible}
              style={styles.ValueSelectionsFieldGroup}
              required={this.formData.required}
              helper={this.formData.required ? "Required" : null}
              maxContentHeight={
                this.formData.valueSelections.length > 6 ? 248 : null
              }
            />
          );
        ret.push(
          <FadeView visible={this.state.valueVisible} key='textInputFadeView'>
            <TextInputField
              name='value'
              label={
                this.formData.valueSelections ? "Other" : this.formData.label
              }
              multiline
              required={this.formData.required}
              helper={this.formData.required ? "Required" : null}
              style={styles.valueTextInput}
              autoFocus={this.state.editable && this.initialValueVisible}
              autoSelect={this.state.editable}
              disabled={!this.state.editable}
            />
          </FadeView>
        );
      }
      return ret;
    }
    render() {
      let showDocumentPreview =
        !this.props.disableDocumentPreview && this.previewAnnotation;
      return (
        <Card
          title={this.formData.title}
          titleTooltip={this.formData.title}
          titleNumberOfLines={1}
          titleEllipsisMode='tail'
          titleControlsRight={this.renderTitleControlsRight()}
          titleTextStyle={styles.cardTitleTextStyle}
          surfaceStyle={styles.cardSurface}
          style={[styles.card, this.props.style]}
        >
          {showDocumentPreview && (
            <View
              style={styles.documentAnnotationPreview}
              onLayout={this.handleAnnotationPreviewLayout}
            >
              <Document
                ref={this.documentRef}
                scale={this.state.annotationPreviewScale || 1}
                onScroll={this.props.onDocumentScroll}
                onZoomInPress={this.props.onDocumentZoomInPress}
                onZoomOutPress={this.props.onDocumentZoomOutPress}
                onZoomResetPress={this.props.onDocumentZoomResetPress}
                onRotateLeftPress={this.props.onDocumentRotateLeftPress}
                onRotateRightPress={this.props.onDocumentRotateRightPress}
                toolbarStyle={this.props.documentToolbarStyle}
                toolbarPosition={this.props.documentToolbarPosition}
                height={128}
                width={328}
                imageHeight={this.document.image.height}
                imageWidth={this.document.image.width}
                imageSource={{
                  uri: this.document.image.url,
                }}
                imageAuth={
                  this.props.formData.documentSet.documentImageStorageMode !==
                  this.props.app.dm.manualEntry
                    .DOCUMENT_IMAGE_STORAGE_MODE_DATASET
                }
                zoomLevel={this.props.documentZoomLevel}
                rotate={this.props.documentRotate}
                annotations={this.renderAnnotations()}
              />
            </View>
          )}
          <Form
            name='documentAnnotationForm'
            style={[
              styles.form,
              !showDocumentPreview && styles.formNoDocumentPreview,
            ]}
            onSubmit={this.handleSubmit}
            validateBeforeSubmit
            initialValues={this.formData.values}
            backgroundColor={Colors.surface}
            controlsBottomRight={this.renderButtons()}
            onBeforeNavigation={this.props.onBeforeNavigation}
            onValueChange={this.props.onValueChange}
          >
            {/* <HiddenInputField name='action' /> */}
            <HiddenInputField name='type' />
            <HiddenInputField name='uuid' />

            {this.renderFormFields()}
          </Form>
        </Card>
      );
    }
  }
);
const styles = StyleSheet.create({
  card: {
    flex: 0,
    // ...StyleSheet.padding(0),
    // ...StyleSheet.margin(0, 0, 16, 0),
  },
  cardTitleTextStyle: {
    fontSize: 16,
    paddingRight: 8,
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // whiteSpace: "nowrap",
  },
  cardSurface: {
    ...StyleSheet.margin(0, 0, 8, 0),
    // ...StyleSheet.padding(0),
    // ...StyleSheet.margin(0),
  },
  form: {
    flex: 0,
    ...StyleSheet.padding(0),
  },
  formNoDocumentPreview: {
    ...StyleSheet.padding(4, 0, 0, 0),
  },
  documentAnnotationPreview: {
    height: 144,
    width: "100%",
    position: "relative",
    flex: 1,
    ...StyleSheet.margin(0, 0, 32, 0),
    borderRadius: 4,
    // overflow: "hidden",
  },
  rejectButton: {
    marginRight: 8,
  },
  valueTextInput: {
    height: 80,
  },
  annotation: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.activeQueueItem,
    backgroundColor: StyleSheet.color(Colors.activeQueueItem).rgba(0.05),
  },
  annotationFieldGroup: {
    borderColor: StyleSheet.color("#666666").rgba(0.2),
    backgroundColor: StyleSheet.color("#666666").rgba(0.1),
  },
  annotationReject: {
    borderColor: Colors.secondary,
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.15),
  },
  annotationAccept: {
    borderColor: Colors.primary,
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.15),
  },
  annotationChanged: {
    borderColor: Colors.green,
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.15),
  },
});
