import React from "react";
import R14, {
  Button,
  View,
  Text,
  Colors,
  StyleSheet,
  ScrollView,
  Surface,
  ZoomableView,
  SideSheet,
  ScreenLayout,
  Scroller,
  Image,
  NavigationContainer,
  CenteredForm,
} from "../core";

import Document from "../components/Document";
import DocumentSetPageScroller from "../components/DocumentSetPageScroller";
import DocumentSetFieldScroller from "../components/DocumentSetFieldScroller";
import DocumentAnnotationEditForm from "../components/DocumentAnnotationEditForm";
import DocumentAnnotation from "../components/DocumentAnnotation";

export default R14.connect(
  class ManualEntryDocumentSetDocumentScreen extends React.Component {
    constructor(props) {
      super(props);
      this.handleScroll = this.handleScroll.bind(this);
      this.handleScreenLayout = this.handleScreenLayout.bind(this);
      this.handleScrollViewLayout = this.handleScrollViewLayout.bind(this);
      this.handleAnnotationPress = this.handleAnnotationPress.bind(this);
      this.handleZoomInPress = this.handleZoomInPress.bind(this);
      this.handleZoomOutPress = this.handleZoomOutPress.bind(this);
      this.handleZoomResetPress = this.handleZoomResetPress.bind(this);
      this.handleRotateLeftPress = this.handleRotateLeftPress.bind(this);
      this.handleRotateRightPress = this.handleRotateRightPress.bind(this);
      this.handleCompletePress = this.handleCompletePress.bind(this);
      this.handleRotateSavePress = this.handleRotateSavePress.bind(this);
      this.activeAnnotationRef = React.createRef();
    }
    // get page(){
    //   return this.props.page;
    // }
    componentDidMount() {
      this.props.app.dm.userSession.unsubscribe();
      // let annotation = this.props.documentSet.currentAnnotation;
      // let scroll = this.page.state.scroll;
      this.page.handleComponentMount(this);
      // this.updateActiveAnnotationScroll();
      // console.log("ANNOTATION CURRENT", annotation, scroll);
    }
    componentDidUpdate(prevProps, props) {
      // this.updateActiveAnnotationScroll(prevProps);
      this.page.handleComponentUpdate(this, prevProps, props);
    }
    get page() {
      return this.props.page;
    }
    handleScreenLayout(event) {
      this.page && this.page.updateScreenLayout(event.nativeEvent.layout);
    }
    handleScrollViewLayout(event) {
      this.page && this.page.updateScrollViewLayout(event.nativeEvent.layout);
    }
    handleScroll(event) {
      this.page && this.page.updateScroll(event.nativeEvent.contentOffset);
    }
    handleAnnotationPress(annotation) {
      this.props.documentSet.navToAnnotation(annotation.uuid);
    }
    async handleCompletePress() {
      await this.props.documentSet.complete();
    }
    handleZoomInPress() {
      this.page.zoomIn();
    }
    handleZoomOutPress() {
      this.page.zoomOut();
    }
    handleZoomResetPress() {
      this.page.zoomReset();
    }
    handleRotateLeftPress() {
      this.page.rotateLeft();
    }
    handleRotateRightPress() {
      this.page.rotateRight();
    }
    async handleRotateSavePress() {
      await this.props.documentSet.rotateDocument();
    }
    getAnnotationStyle(annotation, active = false) {
      let styleName = null;
      switch (annotation.type) {
        case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD:
          let activeStr = active ? "Active" : "";
          styleName =
            annotation.metadata.state ===
            this.props.app.dm.manualEntry.STATE_REVIEW
              ? annotation.metadata.reject
                ? `annotation${activeStr}Reject`
                : annotation.metadata.changed
                ? `annotation${activeStr}Changed`
                : `annotation${activeStr}Accept`
              : `annotation${activeStr}`;
          break;
        case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD_GROUP:
          styleName = "annotationFieldGroup";
          break;
      }
      return styleName ? styles[styleName] || null : null;
    }
    renderAnnotations() {
      return this.props.page.annotations.map((annotation) => {
        if (!annotation.sizeExists) return null;
        let isFullPage = this.page.isFullPageAnnotation(annotation);
        let active = this.page.isCurrentAnnotation(annotation);
        let size = this.page.calculateAnnotationSize(annotation);
        let pos = this.page.calculateAnnotationPosition(annotation);
        let onPress = undefined;
        switch (annotation.type) {
          case this.props.app.dm.manualEntry.ANNOTATION_TYPE_FIELD:
            onPress = () => this.handleAnnotationPress(annotation);
            break;
        }

        let props = {
          ...size,
          style: this.getAnnotationStyle(annotation),
          activeStyle: this.getAnnotationStyle(annotation, true),
          top: pos.top,
          left: pos.left,
          key: annotation.uuid,
          // width:
          //   annotation.width * this.page.image.width * this.page.state.scale,
          // height:
          //   annotation.height * this.page.image.height * this.page.state.scale,
          // top: annotation.top * this.page.image.height * this.page.state.scale,
          // left: annotation.left * this.page.image.width * this.page.state.scale,
          onPress: onPress,
          active,
          isFullPage,
          ref: active ? this.activeAnnotationRef : null,
          // onLayout: active
          //   ? (event) =>
          //       console.log(
          //         "Annotation scroll, measure onLayout",
          //         event.nativeEvent
          //       )
          //   : null,
        };
        return <DocumentAnnotation {...props} />;
      });
    }

    render() {
      return (
        <Document
          headerTitle={this.props.page.name}
          headerControlsRight={
            this.props.documentSet.shouldReview ? (
              <Button title='Complete' onPress={this.handleCompletePress} />
            ) : null
          }
          headerStyle={
            this.props.documentSet.shouldReview && styles.headerComplete
          }
          ref={this.page.documentRef}
          onLayout={this.handleScreenLayout}
          onScrollViewLayout={this.handleScrollViewLayout}
          onScroll={this.handleScroll}
          onRotationSavePress={this.handleRotateSavePress}
          onZoomInPress={this.handleZoomInPress}
          onZoomOutPress={this.handleZoomOutPress}
          onZoomResetPress={this.handleZoomResetPress}
          onRotateRightPress={this.handleRotateRightPress}
          onRotateLeftPress={this.handleRotateLeftPress}
          rotateUpdated={this.props.page.rotateUpdated}
          initialScroll={this.props.page.scrollPosition || null}
          toolbarPosition='bottomRight'
          padding={this.props.page.padding}
          page={this.props.page}
          scale={this.props.page.state.scale}
          height={this.props.page.state.height}
          width={this.props.page.state.width}
          imageHeight={this.props.page.image.height}
          imageWidth={this.props.page.image.width}
          imageSource={{
            uri: this.props.page.image.url,
          }}
          imageAuth={
            this.props.documentSet.documentImageStorageMode !==
            this.props.app.dm.manualEntry.DOCUMENT_IMAGE_STORAGE_MODE_DATASET
          }
          zoomLevel={this.props.page.state.zoomLevel}
          rotate={this.props.page.state.rotate}
          annotations={this.renderAnnotations()}
        />
      );
    }
  }
);
const styles = StyleSheet.create({
  documentSets: {
    top: 36,
  },
  documentSetSurface: {
    ...StyleSheet.padding(0),
    // ...StyleSheet.margin(8, 32, 0, 32),
    overflow: "hidden",
    elevation: 4,
  },
  screenLayout: {
    ...StyleSheet.margin(0),
    // position: "relative",
  },
  headerComplete: {
    backgroundColor: StyleSheet.color(Colors.primaryDark).rgba(1),
  },
  headerContentAnnotate: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.1),
  },
  content: {
    flex: 1,
    // position: "relative",
    overflow: "hidden",
    flexDirection: "column",
  },
  scrollViewContent: {
    // position: "rel;ative"
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  scrollViewContainer: {
    position: "relative",
    flex: 1,
    width: "100%",
  },
  // scrollerItem: {
  //   height: 160,
  //   width: 128,
  //   flex: 1,
  //   flexShrink: 0,
  //   ...StyleSheet.padding(8),
  // },
  // scrollView: {
  //   // ...StyleSheet.padding(32),
  //   // backgroundColor: StyleSheet.color(Colors.onBackground).rgba(0.9),
  //   // marginTop: -16
  // },
  documentThumbnailImage: {
    flex: 1,
    backgroundPosition: "center",
  },
  draggableContainer: {
    flex: 0,
    elevation: 12,
    // ...StyleSheet.margin(16, 32, 32, 32),
    // overflow: "hidden",
  },
  areaSelectionView: {
    position: "absolute",
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: StyleSheet.color(Colors.primaryLight).rgba(0.4),
    //backgroundColor: StyleSheet.color(Colors.primaryLight).rgba(0.05),
  },
  zoomButton: {
    borderRadius: 16,
    backgroundColor: StyleSheet.color(Colors.onSurface).rgba(0.05),
  },
  zoomButtonActive: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.4),
  },
  createButton: {
    marginRight: 8,
  },
  cancelButton: {
    marginRight: 4,
  },
  sideSheet: {
    flex: 0,
    flexDirection: "column",
    width: 360,
    flexGrow: 0,
    ...StyleSheet.padding(0),
    zIndex: 2,
  },
  annotationFieldGroup: {
    backgroundColor: StyleSheet.color("#666666").rgba(0.15),
    zIndex: 1,
  },
  annotationActive: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.activeQueueItem,
    backgroundColor: StyleSheet.color(Colors.activeQueueItem).rgba(0.25),
  },
  annotationReject: {
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.15),
  },
  annotationActiveReject: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.secondary,
    backgroundColor: StyleSheet.color(Colors.secondary).rgba(0.3),
  },
  annotationAccept: {
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.15),
  },
  annotationActiveAccept: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.primary,
    backgroundColor: StyleSheet.color(Colors.primary).rgba(0.3),
  },
  annotationChanged: {
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.15),
  },
  annotationActiveChanged: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: Colors.green,
    backgroundColor: StyleSheet.color(Colors.green).rgba(0.3),
  },
});
